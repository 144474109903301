import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import PageTitle from "../components/pageTitle";

export default function OverPage(): JSX.Element {
  return (
    <Layout>
      <Helmet>
        <title>Polle Kinderboek - Over</title>
      </Helmet>
      <PageTitle>Over Polle</PageTitle>
      <div className="h-full font-polle text-justified text-lg md:text-xl lg:text-2xl p-10">
        <div className="flex flex-col lg:flex-row bg-yellow-polle backdrop-blur-lg bg-opacity-80 rounded-lg">
          <div className="p-10">
            <h1 className="text-xl md:text-2xl lg:text-5xl text-center">
              Over Polle
            </h1>
            <br></br>
            <p>
              Polle is een interactief, doeboek voor kinderen tussen de 7 en de
              10 jaar, die wordt ondersteund door een podcast. Polle ervaart de
              wereld als druk, chaotisch en vol mogelijkheden, en daar wordt die
              nogal onrustig van. Omdat Polle alles op hetzelfde moment wil
              ontdekken, vergeet Polle af en toe hoe mooi diens wereld eigenlijk
              al is. En dat moet anders.
            </p>
            <br></br>
            <p>
              Polle’s wereld is gebaseerd op fotografie, illustraties en kleine
              opdrachtjes die de creatieve verbeelding van het kind stimuleren,
              waardoor ze hun eigen wereld (her)ontdekken. Gedurende de reis
              wordt Polle vergezeld door Moos. Samen ontmoeten ze allerlei
              monsters en lopen ze tegen verschillende problemen aan. Om ervoor
              te zorgen dat zoveel mogelijk kinderen zich met Polle kunnen
              identificeren, is Polle non-binair en heeft die geen huidskleur,
              maar is doorzichtig.
            </p>
            <br></br>
            <p>
              Het uiteindelijke doel van het boek is om kinderen de gewoonte aan
              te leren af en toe te vertragen. Ambitie en ontwikkeling is
              fantastisch, maar die kunnen niet bestaan zonder rust.
            </p>
          </div>
          <div className="flex flex-col justify-center">
            <img
              src="/assets/Polle.png"
              className="max-w-full lg:max-w-sm aspect-auto rounded-lg"
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row bg-yellow-polle backdrop-blur-lg bg-opacity-80 rounded-lg my-10">
          <div className="flex flex-col justify-center">
            <img
              src="/assets/profile.jpg"
              className="max-w-full lg:max-w-sm aspect-auto rounded-lg"
            />
          </div>
          <div className="p-10">
            <h1 className="text-xl md:text-2xl lg:text-5xl text-center">
              Over Julia
            </h1>
            <br></br>
            <p>
              Hallo! Mijn naam is Julia Oud en profileer mezelf graag als
              educatief kunstenaar. Met beeldende kunst, film en fotografie,
              maak ik maatschappelijke problemen begrijpelijk en bespreekbaar.
              Ik gebruik beeld als educatieve en universele taal om een
              positievere kijk op de wereld te creëren en maatschappelijke
              verschillen te overbruggen.
            </p>
            <br></br>
            <p>
              Mijn werk richt zich met name op jongeren en is vaak interactief.
              In het dagelijks leven moeten zij zich tot een ongelofelijk aantal
              mensen en situaties verhouden om hun eigen plekje in de
              samenleving te veroveren. En dat is ingewikkeld. Ik werk met
              thema’s zoals geluk, rust en tijdsbeleving en laat een waaier aan
              perspectieven zien, waarmee ik de focus leg op onze dagelijkse
              omgeving en de kleine dingen die we voor lief nemen. Want door je
              bewust te zijn van alles wat al is, leg je de basis voor alles wat
              ooit kan zijn. Door kunst op die manier in te zetten in het
              onderwijs, ben ik ervan overtuigd dat we de weg vrij maken voor de
              ontwikkeling van jongeren op allerlei verschillende gebieden.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
